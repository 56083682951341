import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./Galerie.scss";
import { Helmet } from "react-helmet";
import MediaPopup from "../../components/MediaPopup";

type DeroulantProps = {
  open: boolean;
  toggle: () => void;
  title: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  id?: string;
};
const SectionDeroulante: React.FC<DeroulantProps> = ({
  open,
  toggle,
  title,
  children,
  className = "",
  id
}) => {
  const body = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!body.current) return;
    const b = body.current;
    const h = b.children[0]!.clientHeight;
    b.style.height = (open ? h : 0) + "px";
  }, [open]);
  return (
    <div className={"section-deroulante " + className} id={id}>
      <div className="head" onClick={toggle}>
        <div>{title}</div>
        <img
          src="/img/select-arrow.svg"
          alt=""
          style={{ transform: `rotate(${open ? 0 : -90}deg)` }}
        />
      </div>
      <div className="body" ref={body}>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

const featuredImages: { [key: string]: string[] } = {
  chirurgie_faciale: [
    "/img/galerie/f-1.jpg",
    "/img/galerie/f-2.jpg",
    "/img/galerie/f-3.jpg",
    "/img/galerie/f-4.jpg",
    "/img/galerie/f-5.jpg",
    "/img/galerie/f-6.jpg",
    "/img/galerie/f-7.jpg",
    "/img/galerie/f-8.jpg",
    "/img/galerie/f-9.jpg",
    "/img/galerie/f-10.jpg",
    "/img/galerie/f-11.jpg",
    "/img/galerie/f-12.jpg"
  ],
  chirurgie_mammaire: ["/img/galerie/m-1.jpeg", "/img/galerie/m-2.jpg"],
  chirurgie_dentaire: [
    "/img/galerie/d-1.jpg",
    "/img/galerie/d-2.jpg",
    "/img/galerie/d-3.jpg",
    "/img/galerie/d-4.jpg",
    "/img/galerie/d-5.jpg"
  ],
  chirurgie_silhouette: ["/img/galerie/s-1.jpeg", "/img/galerie/s-2.jpg"],
  greffe_cheveux: [
    // "/img/galerie/c-1.jpg",
    "/img/galerie/c-2.jpg",
    // "/img/galerie/c-3.jpg",
    "/img/galerie/c-4.jpg",
    "/img/galerie/c-5.jpg",
    "/img/galerie/c-6.jpg",
    "/img/galerie/c-7.jpg",
    "/img/galerie/c-8.jpg",
    "/img/galerie/c-9.jpg",
    // "/img/galerie/c-10.jpg",
    // "/img/galerie/c-11.jpg",
    // "/img/galerie/c-12.jpg",
    // "/img/galerie/c-13.jpg",
    "/img/galerie/c-14.jpg",
    "/img/galerie/c-15.jpg"
  ]
};

const Galerie = () => {
  const [open, setOpen] = React.useState<{ [key: string]: boolean }>({
    chirurgie_faciale: true,
    greffe_cheveux: true,
    chirurgie_mammaire: true,
    chirurgie_dentaire: true,
    chirurgie_silhouette: true
  });
  const [zoomed, setZoomed] = React.useState<string | null>(null);
  return (
    <>
      <Header />
      <Helmet>
        <title>Avant-Après - Les résultats de notre chirurgie esthetique</title>
        <meta
          name="description"
          content="Chirurgie faciale, greffe de cheveux, chirurgie mammaire... Découvrez ce que votre corps peut devenir avec la chirurgie."
        />
      </Helmet>
      <main className="galerie">
        <h1 className="big-title">Avant-Après</h1>
        <SectionDeroulante
          open={open.chirurgie_faciale}
          toggle={() =>
            setOpen((a) => ({ ...a, chirurgie_faciale: !a.chirurgie_faciale }))
          }
          title={<h2 className="title">chirurgie faciale</h2>}
          id="chirurgie_faciale"
          className="sect"
        >
          <div className="grid">
            {featuredImages.chirurgie_faciale.map((a) => (
              <img src={a} alt="" onClick={() => setZoomed(a)} />
            ))}
          </div>
        </SectionDeroulante>
        <SectionDeroulante
          open={open.greffe_cheveux}
          toggle={() =>
            setOpen((a) => ({ ...a, greffe_cheveux: !a.greffe_cheveux }))
          }
          title={<h2 className="title">Greffe de cheveux</h2>}
          className="sect"
          id="greffe_cheveux"
        >
          <div className="grid">
            {featuredImages.greffe_cheveux.map((a) => (
              <img src={a} alt="" onClick={() => setZoomed(a)} />
            ))}
          </div>
        </SectionDeroulante>
        <SectionDeroulante
          open={open.chirurgie_mammaire}
          toggle={() =>
            setOpen((a) => ({
              ...a,
              chirurgie_mammaire: !a.chirurgie_mammaire
            }))
          }
          title={<h2 className="title">Chirurgie mammaire</h2>}
          className="sect"
          id="chirurgie_mammaire"
        >
          <div className="grid">
            {featuredImages.chirurgie_mammaire.map((a) => (
              <img src={a} alt="" onClick={() => setZoomed(a)} />
            ))}
          </div>
        </SectionDeroulante>
        <SectionDeroulante
          open={open.chirurgie_silhouette}
          toggle={() =>
            setOpen((a) => ({
              ...a,
              chirurgie_silhouette: !a.chirurgie_silhouette
            }))
          }
          title={<h2 className="title">Chirurgie de la silhouette</h2>}
          className="sect"
          id="chirurgie_silhouette"
        >
          <div className="grid">
            {featuredImages.chirurgie_silhouette.map((a) => (
              <img src={a} alt="" onClick={() => setZoomed(a)} />
            ))}
          </div>
        </SectionDeroulante>
        <SectionDeroulante
          open={open.chirurgie_dentaire}
          toggle={() =>
            setOpen((a) => ({
              ...a,
              chirurgie_dentaire: !a.chirurgie_dentaire
            }))
          }
          title={<h2 className="title">Chirurgie dentaire</h2>}
          className="sect"
          id="chirurgie_dentaire"
        >
          <div className="grid">
            {featuredImages.chirurgie_dentaire.map((a) => (
              <img src={a} alt="" onClick={() => setZoomed(a)} />
            ))}
          </div>
        </SectionDeroulante>
      </main>
      <Footer />
      {zoomed !== null && (
        <MediaPopup close={() => setZoomed(null)}>
          <img
            src={zoomed}
            alt=""
            style={{
              maxWidth: "80vw",
              maxHeight: "80vh",
              width: "100vw",
              objectFit: "contain"
            }}
          />
        </MediaPopup>
      )}
    </>
  );
};

export default Galerie;
