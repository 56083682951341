import React from "react";
import { useNavigate } from "react-router-dom";
import TwoBlocks from "../../../Layouts/TwoBlocks";

const Section_02 = () => {
  const navigate = useNavigate();

  return (
    <TwoBlocks
      className="sect_01"
      left={
        <div>
          <h2 className="title">
            Voyage Pris en charge de&nbsp;A&nbsp;à&nbsp;Z
          </h2>
          <p className="pgh">
            Nous nous occupons de tout : <br />
            Vous serez en contact avec notré réferent à Nice qui vous expliquera
            tout dans les moindre détails en physique ou par téléphone. Tout
            sera plannifié avec vous: date du voyage, réservation du vol ainsi
            que l'hotel qui sera choisi pour sa proximité avec la clinique.
            Laissez vous tenter par l'éxperience Azurea Esthetic Travel et
            surtout bienvenue en Tunisie.
          </p>
          <button
            className="btn dark-button"
            onClick={() => navigate("/contact")}
          >
            Demander un devis
          </button>
        </div>
      }
      right={<img src="/img/tunisie-5.jpg" alt="" />}
    />
  );
};

export default Section_02;
