import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./About.scss";
import { Helmet } from "react-helmet";
const About = () => {
  return (
    <>
      <Header />
      <Helmet>
        <title>A propos - Azurea Esthetic Travel</title>
        <meta
          name="description"
          content="Azurea Esthetic Travel facilite votre voyage médical en Tunisie, en entretenant un réseau de cliniques et chirurgiens partenaires de haute qualité."
        />
      </Helmet>
      <main className="about">
        <h1 className="big-title">A propos</h1>
        <h1 className="title">Azurea Esthetic Travel:</h1>
        <p className="pgh">
          Azurea Esthetic Travel facilite votre voyage médical en Tunisie, en
          entretenant un réseau de cliniques et chirurgiens partenaires de haute
          qualité.
          <br />
          <br />
          Notre vocation est de vous apporter une assistance personnalisée tout
          au long de votre séjour médical. En choisissant notre agence, vous
          partirez l’esprit tranquille et en toute confiance. Vous serez pris en
          charge en France par notre réferent à Nice, dès votre arrivée à
          l’aéroport en Tunisie et jusqu’à votre retour chez vous.
          <br />
          <br />
          Tout sera organisé pour vous dans les moindres détails. Après votre
          retour, un suivi post-opératoire sera mis en place.
        </p>
        <br />
        <h1 className="title">Associé</h1>
        <h2
          className="subtitle"
          style={{ color: "var(--dark-blue)", marginTop: 56, marginBottom: 0 }}
        >
          Docteur GUESMI Marouen médecin esthétique
        </h2>
        <img src="/img/docteur.jpg" alt="" style={{ maxWidth: 'calc(100vw - 2 * var(--margin)',width: 400, borderRadius: 15, marginTop: 36, aspectRatio: '9/10', objectFit: 'cover', objectPosition: 'bottom' }}/>
        <p className="pgh">
          Ma philosophie est la beauté naturelle et l'approche globale du
          visage. En effet, la médecine esthétique évolue très vite ainsi que la
          demande des patient(e)s. Afin d'éviter toute disharmonie dans un
          visage, le traitement esthétique doit prendre en compte le visage dans
          sa globalité pour avoir un résultat harmonieux et naturel. Le meilleur
          traitement esthétique est celui qui passe inaperçu avec une prise en
          charge personnalisée et non standardisée. Un médecin esthétique est un
          véritable spécialiste à l'écoute de vos attentes, qui va vous proposer
          et vous conseiller des traitements adaptés pour obtenir un résultat
          optimal.
        </p>
        <h2
          className="subtitle"
          style={{ color: "var(--dark-blue)", marginTop: 40, marginBottom: 0 }}
        >
          Parcours:{" "}
        </h2>
        <p className="pgh">
          Après des études médicales à la faculté de médecine de Tunis, le
          Docteur GUESMI Marouen se spécialise en médecine esthétique à partir
          de 2014.
          <ul>
            <li>
              Diplôme de Médecine Esthétique Collège International de médecine
              esthétique à Paris (CIME).
            </li>
            <li>
              Diplôme de médecine Morphologique et anti-âge à l'université Paris
              13.
            </li>
            <li>
              Certificat en Techniques d’amincissement de raffermissement.
            </li>
            <li>
              Formation en techniques de radiofréquence microneedeling /
              dermalinfusion .
            </li>
            <li>Formation en techniques d’injection (PRP)</li>
            <li>Formation en techniques de greffe capillaire (FUE /FUT)</li>
            <li>
              Certificat universitaire d’anatomie des structures superficielles
              de la face appliquée aux techniques d’injection et de volumétrie à
              visée esthétique
            </li>
            <li>
              Membre de l’Association Tunisienne de Médecine Esthétique
              (T.A.M.A.S)
            </li>
          </ul>
        </p>
      </main>
      <Footer />
    </>
  );
};

export default About;
