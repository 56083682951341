import React from "react";
import "./StarRating.scss";

type StarProps = {
  color: string;
  border?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};
const Star: React.FC<StarProps> = ({
  color = "white",
  border,
  ...otherProps
}) => (
  <svg
    stroke={border ?? color}
    fill={color}
    strokeWidth="1"
    viewBox="0 0 16 16"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
  </svg>
);

type StarRatingProps = {
  max: number;
  min: number;
  current: number;
  onChange: (a: number) => void;
  className?: string;
};
const StarRating: React.FC<StarRatingProps> = ({
  max,
  min,
  onChange,
  current,
  className = "",
}) => {
  const [value, setValue] = React.useState<number>(current);
  React.useEffect(() => {
    setValue(current);
  }, [current]);
  return (
    <div
      className={"star-rating " + className}
      onMouseLeave={() => {
        setValue(current);
      }}
      onClick={() => {
        onChange(value);
      }}
    >
      {Array.from({ length: max }, (_, i) => {
        if (value === i + 1) return <Star color="var(--blue)" />;
        return (
          <Star
            border="var(--blue)"
            color={i + 1 < value ? "var(--blue)" : "white"}
            onMouseEnter={() => setValue(i + 1)}
          />
        );
      })}
    </div>
  );
};

export default StarRating;
