import React from 'react'
import StepBlock from '../../../components/StepBlock'

const Section_05 : React.FC = () => {
  return (
    <section className="sect_05">
        <h2 className="title">Comment ça marche ?</h2>
        <div className="grid">
          <StepBlock
            index={1}
            title="Prise de contact"
            >
              <p>
                Prise de contact avec notre référent à Nice en physique ou par téléphone et prise de rendez-vous pour une téléconsultation gratuite avec l'un de nos chirurgiens.
              </p>
          </StepBlock>
          <img src="/img/arrow-alt-circle-right.svg" alt=""/>
          <StepBlock
            index={2}
            title="Planification du voyage"
            >
              <p>
                Nous nous occupons de tout: réservation avion, hotel, transport. Partez l'esprit tranquille.
              </p>
          </StepBlock>
          <img src="/img/arrow-alt-circle-right.svg" alt=""/>
          <StepBlock
            index={3}
            title="Paiement"
            >
              <p>
                Une fois tout confirmé, plusieurs modalitées et facilitées de paiement peuvent vous être proposées.
              </p>
          </StepBlock>
          <img src="/img/arrow-alt-circle-right.svg" alt=""/>
          
          <StepBlock
            index={4}
            title="Voyage et intervention"
            >
              <p>
                Nous serons à vos coté du début à la fin de ce voyage, de l'aéropot en Tunisie jusqu'à votre retour. Votre séjour sera géré entièrement par notre équipe.
              </p>
          </StepBlock>
        </div>
    </section>
  )
}

export default Section_05