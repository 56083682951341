import React from "react";
import './HeroPage.scss'

type HeroPageProps = {
  component?: React.ReactNode | React.ReactNode[];
  bg: string;
  className?: string;
};

const HeroPage: React.FC<HeroPageProps> = ({
  component,
  bg,
  className = "",
}) => {
  return (
    <div
      className={"hero-page " + className}
      style={{ backgroundImage: `url('${bg}')` }}
    >
      <div className="overlay">{component}</div>
    </div>
  );
};
export default HeroPage;
