import React from "react";
import { useNavigate } from "react-router-dom";
import TwoBlocks from "../../../Layouts/TwoBlocks";

const Section_01 = () => {
  const navigate = useNavigate();

  return (
    <TwoBlocks
      className="sect_01"
      left={
        <div className="photo">
          <img src="/img/chirurgie-plastique.jpg" alt=""/>
        </div>
      }
      right={
        <div>
          <h2 className="title">Tourisme médical en Tunisie</h2>
          <p className="pgh">
            Le Tourisme Médical est devenu un secteur en plein essor en Tunisie.
            Beaucoup de gens viennent en raison du coût et de la qualité des
            installations dans le secteur de la santé. La Tunisie fournit des
            traitements de meilleure qualité à moindre coût par rapport à
            l’Europe, aux États-Unis et d’autres pays occidentaux. Les
            destinations privilégiées sont la capitale Tunis et la ville
            balnéaire de Sousse. Depuis de nombreuses années la Tunisie est
            devenue un pays incontournable dans le domaine du tourisme médical
            et notre agence répond à une demande de plus en plus importante de
            patients en provenance du monde entier.
          </p>
          <button
            className="btn dark-button"
            onClick={() => navigate("/contact")}
          >
            Obtenez un devis 
          </button>
        </div>
      }
    />
  );
};

export default Section_01;
