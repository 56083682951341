import React from "react";
import "./Switch.scss";

type SwitchProps = {
  className?: string;
  value: boolean;
  onChange: () => void;
};
const Switch: React.FC<SwitchProps> = ({ className, value, onChange }) => {
  return (
    <div className={"switch " + (className || "")+(value ? " on" : " off")} onClick={onChange}>
      <div/>
    </div>
  );
};

export default Switch;
