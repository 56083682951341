import React from "react";
import { Navigate } from "react-router-dom";
import AdminHeader from "../../components/AdminHeader";
import "./Admin.scss";
import useFeedback from "@oriun/use-feedback";
import ContactPopup, { ContactMode } from "../../components/ContactPopup";
import { API_URL } from "../../constantes";
import { Data } from "../../services/Contact";

const Admin = () => {
  const [refresh_key, _refresh] = React.useState<number>(Date.now());
  const refetch = () => _refresh(Date.now());
  const [filter, setF] = React.useState<string>("");
  const [data, setD] = React.useState<Data[]>([]);
  const [popup, setP] = React.useState<{
    mode: ContactMode;
    data: Partial<Data>;
  } | null>(null);
  const sendfeedback = useFeedback();
  const FilterFunction = React.useCallback(
    (a: Data) => {
      if (!filter) return true;
      return (["_id", "firstName", "lastName", "email", "message", "operation"] as (keyof Data)[]).some((key) =>
        //@ts-ignore
        a[key].toLowerCase().includes(filter)
      );
    },
    [filter]
  );
  function dateSort(a: Data, b: Data) {
    return b.createdAt - a.createdAt
  }
  function updateFilter(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.target.value;
    setF(val.toLowerCase());
  }
  React.useEffect(() => {
    if (!sessionStorage.getItem("token")) return;
    const abort = new AbortController();
    fetch(API_URL + "/patient", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
      signal: abort.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          setD(await res.json());
        } else {
        }
      })
      .catch((err) => {
        if (err instanceof DOMException) return;
        console.error(err);
        sendfeedback({
          title: "Une erreur inattendue est survenue",
          success: false,
          message:
            "Si le problème persiste, veuillez contacter un administrateur.",
        });
      });
    return () => {
      console.log("aborted");
      abort.abort("component dismissed");
    };
  }, [refresh_key]);

  function deleteContact(id: string) {
    fetch(API_URL + "/patient/" + id, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then(async (res) => {
        if (res.ok) {
          sendfeedback({
            title: "Suppression réussie",
            success: true,
          });
          refetch();
        } else {
          console.log(res);
          throw new Error();
        }
      })
      .catch((err) => {
        console.error(err);
        sendfeedback({
          title: "Une erreur inattendue est survenue",
          success: false,
          message:
            "Si le problème persiste, veuillez contacter un administrateur.",
        });
      });
  }

  function open(data: Partial<Data>) {
    setP({ mode: ContactMode.UPDATE, data });
  }
  function newContact() {
    setP({ mode: ContactMode.CREATE, data: {} });
  }
  function close() {
    setP(null);
    refetch();
  }

  if (!sessionStorage.getItem("token")) {
    return <Navigate to="/admin/login" />;
  }
  console.log(popup?.mode ?? ContactMode.UPDATE);
  return (
    <>
      <AdminHeader />
      <main className="admin">
        <div className="head">
          <h1 className="title">
            <img src="/img/contact.svg" alt="" width={40} height={40} />
            Contact
          </h1>
          <div className="search">
            <input
              placeholder="Rechercher"
              value={filter}
              onChange={updateFilter}
            />
            <img src="/img/search.svg" alt="" width={20} height={20} />
          </div>
        </div>
        <div className="content">
          <div className="add" onClick={newContact}>
            <div>
              <img src="/img/add.svg" alt="" width={28} height={28} />
              <span className="sur-pgh">Ajouter un contact</span>
            </div>
          </div>
          {data.filter(FilterFunction).sort(dateSort).map((contact) => {
            const { _id, createdAt, firstName, lastName, operation } = contact;
            return (
              <div className="item" data-id={_id}>
                <span className="date sur-pgh">
                  {new Date(createdAt).toLocaleDateString()}
                </span>
                <span className="email sur-pgh">{firstName} {lastName}</span>
                <span className="presta sur-pgh">{operation}</span>
                <button onClick={() => open(contact)}>Ouvrir</button>
                <img
                  src="/img/delete.svg"
                  alt=""
                  width={30}
                  height={30}
                  onClick={() => deleteContact(_id)}
                />
              </div>
            );
          })}
        </div>
      </main>
      {popup && (
        <ContactPopup
          close={close}
          mode={popup?.mode ?? ContactMode.UPDATE}
          data={popup?.data || {}}
        />
      )}
    </>
  );
};

export default Admin;
