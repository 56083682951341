import React from "react";
import "./QuoteBlock.scss";

type QuoteBlockProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  author: string;
};
const QuoteBlock: React.ForwardRefExoticComponent<
  QuoteBlockProps & React.RefAttributes<HTMLDivElement>
> = React.forwardRef(({ children, className = "", author }, ref) => {
  return (
    <div className={"quote_block " + className} ref={ref}>
      <img src="/img/quote.svg" alt="" />
      <img src="/img/quote.svg" alt="" />
      <span className="author sur-pgh">{author}</span>
      {children}
    </div>
  );
});

export default QuoteBlock;
