import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import './Header.scss'
import useClickOutside from '@oriun/use-click-outside';

const presta = [
  "/chirurgie-faciale",
  "/greffe-cheveux",
  "/chirurgie-mammaire",
  "/chirurgie-dentaire",
  "/chirurgie-silhouette"
]
const Header = () => {
  const [prestationOpen, setPO] = React.useState<boolean>(false);
  const [mobileOpen, setMO] = React.useState<boolean>(false)
  const ref = useClickOutside(prestationOpen, ()=>setPO(false))
  const location = useLocation()
  React.useEffect(()=>{
    setPO(false)
    setMO(false)
  },[location])
  return (
    <header>
      <Link to="/"><img src="/img/logo-2.svg" alt="azurea esthetic travel"/></Link>
      <nav className='desktop'>
        <div
          className={'deroulant '+ (prestationOpen? 'open': 'close')}
          onMouseEnter={()=>setPO(true)}
          onClick={()=>setPO(a=>!a)}
          onMouseLeave={()=>setPO(false)}
          ref={ref}
        >
          <span className={"hf "+(presta.includes(location.pathname) ? "current":"")}>Prestations</span>
          <div className='content'>
            <div className="list">
              <Link to="/chirurgie-faciale" className="hf">chirurgie&nbsp;faciale</Link>
              <Link to="/greffe-cheveux" className="hf">greffe&nbsp;de&nbsp;cheveux</Link>
              <Link to="/chirurgie-mammaire" className="hf">chirurgie&nbsp;mammaire</Link>
              <Link to="/chirurgie-dentaire" className="hf">chirurgie&nbsp;dentaire</Link>
              <Link to="/chirurgie-silhouette" className="hf">chirurgie&nbsp;de&nbsp;la&nbsp;silhouette</Link>
            </div>
          </div>
        </div>
        <Link to="/galerie" className={"hf "+(location.pathname === "/galerie" ? "current":"")}>avant-après</Link>
        <Link to="/about" className={"hf "+(location.pathname === "/about" ? "current":"")}>a propos</Link>
        <Link to="/contact" className={"hf "+(location.pathname === "/contact" ? "current":"")}>contact</Link>
      </nav>
      <img src="/img/menu.svg" alt="" className='menu mobile' onClick={()=>setMO(true)}/>
      <nav className={'mobile '+(mobileOpen ? 'open':'')}>
        <img src="/img/close.svg" alt="" className='close' onClick={()=>setMO(false)}/>
        <h6 className='sur-pgh'>Menu</h6>
        <h6 className='hf'>Prestations</h6>
        <div className="list">
          <Link to="/chirurgie-faciale" className="hf">chirurgie faciale</Link>
          <Link to="/greffe-cheveux" className="hf">greffe de cheveux</Link>
          <Link to="/chirurgie-mammaire" className="hf">chirurgie mammaire</Link>
          <Link to="/chirurgie-dentaire" className="hf">chirurgie dentaire</Link>
          <Link to="/chirurgie-silhouette" className="hf">chirurgie de la silhouette</Link>
        </div>
        <Link to="/galerie" className={"hf "+(location.pathname === "/galerie" ? "current":"")}>avant-après</Link>
        <Link to="/about" className={"hf "+(location.pathname === "/about" ? "current":"")}>a propos</Link>
        <Link to="/contact" className={"hf "+(location.pathname === "/contact" ? "current":"")}>contact</Link>
      </nav>
    </header>
  )
}

export default Header