import React from "react";
import Popup from "../../Layouts/Popup";
import "./ContactPopup.scss";
import useFeedback from "@oriun/use-feedback";
import { API_URL } from "../../constantes";
import { Data } from "../../services/Contact";
import {
  ButtonInput,
  DateInput,
  InputSpan,
  SelectInput,
  SendInput,
  TextInput,
  TwoOrMoreArray,
} from "../Inputs";
import DispoPopup, { Day } from "../DispoPopup";
import UploadPopup from "../UploadPopup";
import useDiffState from "@oriun/use-diff-state";
import Switch from "../Switch";

type DeroulantProps = {
  open: boolean;
  toggle: () => void;
  title: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  id?: string;
};
const SectionDeroulante: React.FC<DeroulantProps> = ({
  open,
  toggle,
  title,
  children,
  className = "",
  id,
}) => {
  const body = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!body.current) return;
    const b = body.current;
    const h = b.children[0]!.clientHeight;
    b.style.height = (open ? h : 0) + "px";
  }, [open]);
  return (
    <div className={"section-deroulante " + className} id={id}>
      <div className="head" onClick={toggle}>
        <div>{title}</div>
        <img
          src="/img/select-arrow-dark.svg"
          alt=""
          style={{ transform: `rotate(${open ? 0 : -90}deg)` }}
        />
      </div>
      <div className="body" ref={body}>
        <div className="content" style={{ display: "flex", flexWrap: "wrap" }}>
          {children}
        </div>
      </div>
    </div>
  );
};

const prestations: TwoOrMoreArray<string> = [
  "Chirurgie faciale",
  "Greffe de cheveux",
  "Chirurgie dentaire",
  "Chirurgie de la silhouette",
  "Chirurgie mammaire",
];

export const enum ContactMode {
  CREATE,
  UPDATE,
  // READ,
}
type ContactPopup = {
  close: () => void;
  mode: ContactMode;
  data: Partial<Data>;
};
const ContactPopup: React.FC<ContactPopup> = ({ close, mode, data = {} }) => {
  const sendfeedback = useFeedback();
  const [contact, setC, hardUpdate] = useDiffState(data);
  const [dispoOpen, setDispo] = React.useState<boolean>(false);
  const [uploadOpen, setUpload] = React.useState<boolean>(false);
  const [photosOpen, showPhoto] = React.useState<boolean>(false);
  function updateContact() {
    const body = JSON.stringify({
      ...contact,
      message: contact.message || "* Créé depuis le panel admin",
      availability:
        typeof contact.availability === "string"
          ? contact.availability
          : JSON.stringify(contact.availability),
    } as Data);
    fetch(API_URL + "/patient/" + contact._id, {
      method: "PATCH",
      body,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        if (res.ok) {
          sendfeedback({
            title: "Modification réussie",
            success: true,
          });
          close();
        } else {
          console.log(res);
          throw new Error();
        }
      })
      .catch((err) => {
        console.error(err);
        sendfeedback({
          title: "Une erreur inattendue est survenue",
          success: false,
          message:
            "Si le problème persiste, veuillez contacter un administrateur.",
        });
      });
  }
  const canSend = React.useMemo(() => {
    const a = contact;
    if (!a.lastName) return false;
    if (!a.firstName) return false;
    if (!a.civility) return false;
    if (!a.birthDate) return false;
    if (!a.email || !a.email.includes("@")) return false;
    if (!a.zipCode) return false;
    // if (!a.availability?.length) return false;
    if (!a.phoneNumber) return false;
    // if (!a.message) return false;
    if (!a.operation) return false;
    return true;
  }, [contact]);
  function createContact() {
    const body = new FormData();
    body.append("lastName", contact.lastName!);
    body.append("firstName", contact.firstName!);
    body.append("civility", contact.civility!);
    body.append("birthDate", contact.birthDate!);
    body.append("email", contact.email!);
    body.append("zipCode", contact.zipCode!);
    body.append("availability", JSON.stringify(contact.availability || {}));
    body.append("phoneNumber", contact.phoneNumber!);
    body.append("message", contact.message || "* Créé depuis le panel admin");
    body.append("operation", contact.operation!);
    body.append("CGUAgreed", "true");

    fetch(API_URL + "/patient", {
      method: "POST",
      body,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        if (res.ok) {
          sendfeedback({
            title: "Création réussie",
            success: true,
          });
          close();
        } else {
          console.log(res);
          throw new Error();
        }
      })
      .catch((err) => {
        console.error(err);
        sendfeedback({
          title: "Une erreur inattendue est survenue",
          success: false,
          message:
            "Si le problème persiste, veuillez contacter un administrateur.",
        });
      });
  }
  function send() {
    (contact._id ? updateContact : createContact)();
  }
  function updateOffer() {
    if (!contact._id)
      return sendfeedback({
        title: "Une erreur inattendue est survenue",
        success: false,
        message:
          "Si le problème persiste, veuillez contacter un administrateur.",
      });

    fetch(API_URL + `/patient/acceptsOffer/${contact._id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        answer: !contact.acceptedOffer?.hasAcceptedOffer,
      }),
    })
      .then(async (res) => {
        if (res.ok) {
          sendfeedback({
            title: "Success",
            success: true,
          });
          setC({
            acceptedOffer: {
              hasAcceptedOffer: !contact.acceptedOffer?.hasAcceptedOffer,
            }
          });
        } else throw new Error();
      })
      .catch((err) => {
        console.log(err.message);
        sendfeedback({
          title: "Une erreur inattendue est survenue",
          success: false,
          message:
            "Si le problème persiste, veuillez contacter un administrateur.",
        });
      });
  }
  return (
    <Popup close={close}>
      <div className="contact-popup">
        <h2 className="title">
          Contact {contact._id && `#${contact._id.slice(0, 4)}`}
          {mode === ContactMode.UPDATE && (
            <>
              <Switch
                className="offer"
                value={!!contact.acceptedOffer?.hasAcceptedOffer}
                onChange={updateOffer}
              />
              <span className="sp">
                {contact.acceptedOffer?.hasAcceptedOffer
                  ? "Client"
                  : "Prospect"}
              </span>
            </>
          )}
        </h2>
        <img
          src="/img/close-dark.svg"
          className="mobile"
          alt=""
          onClick={close}
        />
        <div className="mobile">
          {Object.entries(contact).map(([key, value]: [string, any]) => {
            if (
              [
                "_id",
                "createdAt",
                "__v",
                "pics",
                "acceptedOffer",
                "CGUAgreed",
              ].includes(key)
            )
              return;
            let k = key[0].toUpperCase() + key.slice(1).toLowerCase();
            let v: React.ReactNode = value?.toString();
            if (k === "Availability") {
              v = (
                (typeof v === "object"
                  ? value
                  : JSON.parse(value as string)) as {
                  day: string;
                  span: string;
                }[]
              ).map(({ day, span }) => (
                <>
                  <span className="pgh">{day}:</span> {span}
                  <br />
                </>
              ));
            }
            return (
              <div>
                <h6 className="sp">{k}</h6>
                <span className="sur-pgh">{v}</span>
              </div>
            );
          })}
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <SelectInput
            value={contact.civility}
            onChange={(a) => setC({ civility: a as string })}
            placeholder="Civilité"
            options={["Mr", "Mme"]}
            mode="-dark"
          />
          <TextInput
            value={contact.lastName}
            onChange={(a) => setC({ lastName: a })}
            placeholder="Nom"
            span={InputSpan.S}
          />
          <TextInput
            value={contact.firstName}
            onChange={(a) => setC({ firstName: a })}
            placeholder="Prénom"
            span={InputSpan.S}
          />
          <SelectInput
            value={contact.operation}
            onChange={(a) => setC({ operation: a as string })}
            placeholder="Type de prestation"
            options={prestations}
            mode="-dark"
          />
          <DateInput
            value={contact.birthDate}
            onChange={(a) =>
              setC({
                birthDate:
                  a instanceof Date ? a.toLocaleString() : (a as string),
              })
            }
            placeholder="Date de naissance"
            span={InputSpan.S}
          />
          <TextInput
            value={contact.zipCode}
            onChange={(a) => setC({ zipCode: a })}
            placeholder="Code postal"
            span={InputSpan.S}
          />
          <TextInput
            value={contact.message}
            onChange={(a) => setC({ message: a })}
            placeholder="Message (maximum 150 caractères)"
            span={InputSpan.L}
          />
          <TextInput
            value={contact.email}
            onChange={(a) => setC({ email: a })}
            placeholder="Address mail"
          />
          {/* <ButtonInput
            Head={<img src="/img/upload-dark.svg" alt="" />}
            placeholder="Ajouter des photos"
            onClick={() => setUpload(true)}
          /> */}
          <TextInput
            value={contact.phoneNumber}
            onChange={(a) => setC({ phoneNumber: a })}
            placeholder="Téléphone"
          />
          <ButtonInput
            Head={<img src="/img/calendar-dark.svg" alt="" />}
            Tail={
              <img
                src="/img/select-arrow-dark.svg"
                alt=""
                style={{ transform: "rotate(270deg)" }}
              />
            }
            placeholder="Disponibilités"
            onClick={() => setDispo(true)}
          />
          {/* <NoInput />
          <NoInput />
          <NoInput /> */}
          <SendInput
            content={contact._id ? "Modifier" : "Ajouter"}
            onClick={canSend ? send : console.log}
            disabled={!canSend}
          />
        </form>
        {mode === ContactMode.UPDATE && (
          <SectionDeroulante
            open={photosOpen}
            toggle={() => showPhoto((a) => !a)}
            title={
              <h4 className="sur-pgh" style={{ margin: 0 }}>
                Photos
              </h4>
            }
          >
            {contact.pics?.map((a) => (
              <img
                src={API_URL + "/uploads/" + contact.email! + "/" + a}
                alt=""
                style={{
                  width: 400,
                  maxWidth: "100%",
                  aspectRatio: "1",
                  objectFit: "contain",
                  border: "2px solid lightgrey",
                }}
              />
            ))}
          </SectionDeroulante>
        )}
      </div>
      {dispoOpen && (
        <DispoPopup
          onChange={(a) => hardUpdate((f: any) => ({ ...f, availability: a }))}
          values={
            typeof contact.availability === "string"
              ? JSON.parse(contact.availability)
              : contact.availability || []
          }
          days={[
            Day.Lundi,
            Day.Mardi,
            Day.Mercredi,
            Day.Jeudi,
            Day.Vendredi,
            Day.Samedi,
            Day.Dimanche,
          ]}
          spans={["9h-13h", "14h-18h", "+18h"]}
          close={() => setDispo(false)}
        />
      )}
      {uploadOpen && (
        <UploadPopup
          onChange={(a: File[]) => hardUpdate((f: any) => ({ ...f, files: a }))}
          values={contact.files ?? []}
          close={() => setUpload(false)}
        />
      )}
    </Popup>
  );
};

export default ContactPopup;
