import React from "react";
import "./AdminHeader.scss";

const AdminHeader = () => {
  return (
    <header className="admin">
      <div className="border" />
      <img src="/img/logo-admin.svg" alt="azurea esthetic travel" />
    </header>
  );
};

export default AdminHeader;
