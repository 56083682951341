import React from "react";
import Popup from "../../Layouts/Popup";
import "./UploadPopup.scss";
import useFeedback from "@oriun/use-feedback";

type UploadPopupProps = {
  onChange: (files: File[]) => void;
  values: File[];
  close: () => void;
  maxFiles?: number;
};

function displayFileSize(octet: number): string {
  if (octet < 1_000) return octet + "o";
  if (octet < 1_000_000) return Math.round(octet / 1000) + "Ko";
  if (octet < 1_000_000_000) return Math.round(octet / 1000_000) + "Mo";
  if (octet < 1_000_000_000_000) return Math.round(octet / 1000_000_000) + "Go";
  return Math.round(octet / 1000_000_000_000) + "To";
}

const UploadPopup: React.FC<UploadPopupProps> = ({
  close,
  onChange,
  maxFiles = 5,
  values,
}) => {
  const sendfeedback = useFeedback();
  const debug = window.location.search.includes("debug");
  function handleFiles(files: FileList) {
    try {
      if (values.length + files.length >= maxFiles) {
        return sendfeedback({
          title: `Vous ne pouvez joindre que ${maxFiles} fichiers`,
          success: false,
        });
      }
      const as_array = Array.from(files);
      if (as_array.find((file) => file.size > 5_000_000)) {
        debug && alert(as_array.map((a) => a.size).join(","));
        return sendfeedback({
          title: `Fichier trop volumineux (max: 5MB).`,
          success: false,
        });
      }
      onChange([...values, ...as_array].slice(0, maxFiles));
    } catch (err) {
      debug && alert((err as Error).message);
      debug &&
        sendfeedback({
          title: (err as Error).message,
          success: false,
        });
    }
  }
  function importFile(ev: React.ChangeEvent<HTMLInputElement>) {
    const target = ev.target as HTMLInputElement;
    if (!target.files) return;
    handleFiles(target.files);
  }
  function remove(index: number) {
    console.log("remove activated");
    return (e: React.MouseEvent) => {
      e.stopPropagation();
      console.log("remove used");
      const newArr = [...values];
      newArr.splice(index, 1);
      console.log("Remove", index, values.length, newArr.length);
      onChange(newArr);
    };
  }
  function show(file: File) {
    return () => {
      const url = URL.createObjectURL(file);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);
    };
  }
  return (
    <Popup close={close}>
      <div className="upload-popup">
        <div className="upload-head">
          <div>
            <h6 className="sur-pgh">Joignez des photos à votre demande</h6>
            <span className="sub-pgh">Optionnel (max: 5)</span>
          </div>
          <img
            src="/img/close.svg"
            alt=""
            width={20}
            height={20}
            onClick={close}
          />
        </div>
        <div className="upload-main">
          {values.map((a: File, index: number) => {
            return (
              <div className="upload-item" onClick={show(a)}>
                <span className="name sp">{a.name.slice(0, 50)}</span>
                <span className="size sp">{displayFileSize(a.size)}</span>
                <img
                  src="/img/close.svg"
                  alt=""
                  width={14}
                  height={14}
                  onClick={remove(index)}
                />
              </div>
            );
          })}
        </div>
        <div className="buttons">
          <button className="btn dark-button small" style={{ position: 'relative', overflow: 'hidden', cursor: 'pointer'}}>
            Ajouter
            <input
              type="file"
              accept="image/*"
              onChange={importFile}
              onClick={(e) => e.stopPropagation()}
              multiple
              style={{ position: 'absolute', inset: '0', opacity: 0, cursor: 'pointer' }}
            />
          </button>
          <button onClick={close} className="btn dark-button small">
            Valider
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default UploadPopup;
