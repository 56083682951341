import React from 'react'
import './BasicCard.scss'

type BasicCardPRops = {
    title: React.ReactNode;
    img: string;
    onClick?: React.MouseEventHandler;
    large?: boolean
}
const BasicCard: React.FC<BasicCardPRops> = ({ title, onClick, img, large=false }) => {
    return <div className={"basic_card "+(large? 'large':'')} onClick={onClick}>
        <img src={img} alt="" />
        <div>
            <h4 className='subtitle'>{title}</h4>
        </div>
    </div>
}

export default BasicCard