import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./FormComplete.scss";

type FormCompleteProps = {
  title: string;
  content: string;
}
const FormComplete: React.FC<FormCompleteProps> = ({ title, content }) => {
  return (
    <>
      <Header />
      <main className="form-complete">
        <div className="content">
          <img src="/img/plane.svg" alt="" height={102} width={102} />
          <h2 className="title">{title}</h2>
          <p className="pgh-subtitle">
            {content}
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default FormComplete;
