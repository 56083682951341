import React from "react";
import {
  InputSpan,
  TextInputProps,
  SelectInputProps,
  ButtonInputProps,
  SendInputProps,
  DateInputProps,
} from "./Inputs.types";
import './Inputs.scss'
import useClickOutside from '@oriun/use-click-outside'

export const NoInput: React.FC<{ span?: InputSpan; className?: string; content?: React.ReactNode; }> = ({
  span = InputSpan.M,
  className = "",
  content,
}) => {
  return (
    <div
      className={"no-input input " + className + " pgh size" + span}
    >{content}</div>
  );
};

export const TextInput: React.FC<TextInputProps> = ({
  placeholder,
  onChange,
  value = "",
  span = InputSpan.M,
  className = "",
  type="text"
}) => {
  const Inside = React.useCallback((a: any)=>React.createElement(span === InputSpan.L ? 'textarea' : 'input',a),[])
  const changeFunction: React.ChangeEventHandler<HTMLInputElement> = function (
    event
  ) {
    const v = event.target.value;
    onChange(v);
  };
  return (
    <div className={"text-input input " + className + " pgh size" + span}>
      <Inside
        type={type}
        className="pgh"
        placeholder={placeholder}
        value={value}
        onChange={changeFunction}
      />
    </div>
  );
};

export const SelectInput: React.FC<SelectInputProps> = ({
  className = "",
  value,
  placeholder,
  options,
  span = InputSpan.M,
  onChange,
  name,
  mode = ''
}) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const ref = useClickOutside(open, ()=>setOpen(false))
  const hovered = React.useRef<boolean>(false)
  React.useEffect(()=>{
    if(!open || !ref.current) return
    else if(open) ref.current.querySelector('select')?.focus()
  }, [open, setOpen])
  return (
    <div onFocus={()=>setOpen(true)} onBlur={()=>!hovered.current && setOpen(false)} className={"select-input input " + className + " pgh size" + span + (open ? ' open': '')} onMouseEnter={()=>{hovered.current = true}} onMouseLeave={()=>{hovered.current = false}} onClick={()=>setOpen(a=>!a)} ref={ref}>
      <select name={name} />
      <img src={"/img/select-arrow"+mode+".svg"} alt=""/>
      <span className={"main "+(value && !open ? '' : 'empty')}>{value && !open ? value : placeholder}</span>
      <div className="options">
        {options.map(a=><div className="option" key={a?.toString()} onMouseDown={()=>onChange(a)}>{a}</div>)}
      </div>
    </div>
  );
};

export const DateInput: React.FC<DateInputProps> = ({
  className = "",
  value,
  placeholder,
  span = InputSpan.M,
  onChange,
  name
}) => {
  const ref = React.useRef<HTMLInputElement>(null)
  function open(){
    if(!ref.current) return
    ref.current.click()
  }
  return (
    <div onClick={open} className={"date-input input " + className + " pgh size" + span+(value ? '' : ' empty')} ref={ref}>
      <input name={name} value={value && new Date(value).toISOString().slice(0,10)} className="pgh" type="date" onChange={e=>onChange(e.target.value)} onClick={e=>e.stopPropagation()} ref={ref}/>
      <span className="main">{placeholder}</span>
    </div>
  );
};

export const ButtonInput: React.FC<ButtonInputProps> = ({
  className = "",
  placeholder,
  onClick,
  Head,
  Tail,
  span = InputSpan.M,
}) => {
  return (
    <div
      className={"button-input input " + className + " pgh size" + span}
      onClick={onClick}
    >
      {Head && <div className="head">{Head}</div> }
      <div className="content">{placeholder}</div>
      {Tail && <div className="tail">{Tail}</div> }
    </div>
  );
};

export const SendInput: React.FC<SendInputProps> = ({
  className = "",
  content,
  onClick,
  span = InputSpan.M,
  disabled = false,
  loading = false
}) => {
  return (
    <div
      className={"send-input input " + className + " sur-pgh size" + span + (disabled ? ' disabled':'') + (loading ? ' loading':'')}
      onClick={onClick}
    >
      {content}
    </div>
  );
};
