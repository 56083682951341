import React from "react";
import HeroPage from "../../Layouts/HeroPage";
import "./Hero.scss";

const transitionDuration = 0.8; //secondes
const transitionDelay = 8; //secondes
const Hero: React.FC = () => {
  const scrollable = React.useRef<HTMLDivElement>(null);
  const [carouselIndex, setC] = React.useState<number>(0);
  const carouselTimeout = React.useRef<number>(0);
  React.useEffect(() => {
    carouselTimeout.current = setTimeout(() => {
      setC((a) => (a + 1) % 4);
    }, transitionDelay * 1_000) as unknown as number;
    return () => {
      clearTimeout(carouselTimeout.current);
    };
  }, [carouselIndex, setC]);

  React.useEffect(() => {
    if (!scrollable.current) return;
    const s = scrollable.current;
    (Array.from(s.children) as HTMLElement[]).forEach((child) => {
      if (child.classList.contains("controls")) return;
      child.style.transitionDuration = `${transitionDuration}s`;
      child.style.transitionProperty = `transform`;
      child.style.transitionTimingFunction = `ease-in-out`;
      child.style.transform = `translateX(-${carouselIndex}00%)`;
    });
  }, [carouselIndex]);
  React.useEffect(() => {
    if (!scrollable.current) return;
    scrollable.current.style.setProperty(
      "--transitionDuration",
      transitionDuration + "s"
    );
    scrollable.current.style.setProperty(
      "--transitionDelay",
      transitionDelay + "s"
    );
  }, []);
  return (
    <section className="hero" ref={scrollable}>
      <div className="controls buttons">
        <img
          src="/img/arrow.svg"
          alt=""
          onClick={() => setC((a) => (a === 0 ? 3 : a - 1))}
        />
        <img
          src="/img/arrow.svg"
          alt=""
          onClick={() => setC((a) => (a + 1) % 4)}
        />
      </div>
      <div className="controls time" key={carouselIndex} />
      <HeroPage
        className={"first " + (carouselIndex === 0 ? "current" : "")}
        component={
          <>
            <h1 className="big-title desktop">
              Azurea
              <br />
              Esthetic Travel
            </h1>
            <h1 className="big-title mobile">
              Azurea
              <br />
              Esthetic
              <br />
              Travel
            </h1>
            <p className="big-subtitle">
              Voyager pour une harmonie entre corps et esprit
            </p>
            <button
              className="btn dark-button"
              onClick={() =>
                window.scrollTo({
                  top: window.screen.availHeight,
                  behavior: "smooth",
                })
              }
            >
              Découvrir
            </button>
          </>
        }
        bg="/img/tunisie.jpg"
      />
      <HeroPage
        className={"second " + (carouselIndex === 1 ? "current" : "")}
        component={
          <>
            <h1 className="big-title mobile">
              Tourisme
              <br/>
              médical
              <br/>
              en tunisie
              </h1>
            <h1 className="big-title desktop">
              Tourisme médical
              <br />
              en tunisie
            </h1>
            <p className="big-subtitle">
              Profitez d'un cadre idyllique pendant et après votre intervention
            </p>
            <button
              className="btn dark-button"
              onClick={() =>
                window.scrollTo({
                  top: window.screen.availHeight,
                  behavior: "smooth",
                })
              }
            >
              Découvrir
            </button>
          </>
        }
        bg="/img/tunisie-4.jpg"
      />
      <HeroPage
        className={"third " + (carouselIndex === 2 ? "current" : "")}
        component={
          <>
            <h1 className="big-title desktop">
              Un service
              <br /> de qualité
            </h1>
            <h1 className="big-title mobile">Un service de qualité</h1>
            <p className="big-subtitle">
              Choisissez un réseau de cliniques expertes
            </p>
            <button
              className="btn dark-button"
              onClick={() =>
                window.scrollTo({
                  top: window.screen.availHeight,
                  behavior: "smooth",
                })
              }
            >
              Découvrir
            </button>
          </>
        }
        bg="/img/accompagnement.jpg"
      />
      <HeroPage
        className={"fourth " + (carouselIndex === 3 ? "current" : "")}
        component={
          <>
            <h1 className="big-title">Suivi personnalisé</h1>
            <p className="big-subtitle">
              Planifions ensemble votre intervention
            </p>
            <button
              className="btn dark-button"
              onClick={() =>
                window.scrollTo({
                  top: window.screen.availHeight,
                  behavior: "smooth",
                })
              }
            >
              Découvrir
            </button>
          </>
        }
        bg="/img/accompagnement-2.jpg"
      />
    </section>
  );
};

export default Hero;
