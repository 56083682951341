import React from 'react'
import { useNavigate } from 'react-router-dom';

const Section_06 = () => {
    const navigate = useNavigate()
    const buttons: string[] = [
        "Chirurgie dentaire",
        "Rhinoplastie",
        "Chirurgie faciale",
        "Chirurgie mammaire",
        "Chirurgie de la silhouette",
        "Greffe de cheveux"
    ]
  return (
    <section className='sect_06'>
        <img src="/img/man-2.jpg" alt="" height={380}/>
        <div className="text">
            <h2 className="title">Votre référent Samy à Nice</h2>
            <h4 className="pgh-subtitle">Profitez gratuitement d'un rendez-vous à Nice ou à distance.</h4>
            <p className="pgh">Contactez nous pour une :</p>
            <div className='list'>
                {buttons.map(a=><button className='pgh' key={a} onClick={()=>navigate('/contact?pre='+a)}>{a}</button>)}
            </div>
        </div>
    </section>
  )
}

export default Section_06