import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./Privacy.scss";

const SITE_URL = "https://azurea-esthetic-travel.fr";
const SITE_NAME = "Azurea Esthetic Travel";
const CONTACT_EMAIL = "contact@azurea-esthetic-travel.fr";

const Legal = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <main className="legal">
        <button className="back" onClick={() => navigate(-1)}>
          <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM21 7L1 7V9L21 9V7Z"
              fill="black"
            />
          </svg>
          Retour
        </button>
        <h2>Politique de confidentialité</h2>
        <p>
          Le site web <a href={SITE_URL}>{SITE_URL}</a> est la propriété d'{SITE_NAME}, qui est le
          responsable du traitement de vos données personnelles.
        </p>
        <p>
          Nous avons adopté la présente Politique de confidentialité, qui
          détermine comment nous traitons les informations collectées par
          {SITE_NAME}, qui fournit également les raisons pour lesquelles nous
          devons collecter certaines données personnelles vous concernant. Par
          conséquent, vous devez lire cette politique de confidentialité avant
          d'utiliser le site web d'{SITE_NAME}.
        </p>
        <p>
          Nous prenons soin de vos données personnelles et nous nous engageons à
          en garantir la confidentialité et la sécurité.
        </p>
        <h2>Les données personnelles que nous collectons :</h2>
        <p>
          Lorsque vous visitez le site {SITE_NAME}, nous collectons
          automatiquement certaines informations sur votre appareil, y compris
          des informations sur votre navigateur web, votre adresse IP, votre
          fuseau horaire et certains des cookies installés sur votre appareil.
          En outre, lorsque vous naviguez sur le site, nous recueillons des
          informations sur les pages Web ou les produits individuels que vous
          consultez, les sites Web ou les termes de recherche qui vous ont
          renvoyé au site et la façon dont vous interagissez avec le site. Nous
          désignons ces informations collectées automatiquement par le terme
          "informations sur les appareils". En outre, nous pourrions collecter
          les données personnelles que vous nous fournissez (y compris, mais
          sans s'y limiter, le nom, le prénom, l'adresse, les informations de
          paiement, etc.) lors de l'inscription afin de pouvoir exécuter le
          contrat.
        </p>
        <h2>Pourquoi traitons-nous vos données ?</h2>
        <p>
          Notre priorité absolue est la sécurité des données des clients et, à
          ce titre, nous ne pouvons traiter que des données minimales sur les
          utilisateurs, uniquement dans la mesure où cela est absolument
          nécessaire pour maintenir le site web. Les informations recueillies
          automatiquement sont utilisées uniquement pour identifier les cas
          potentiels d'abus et établir des informations statistiques concernant
          l'utilisation du site web. Ces informations statistiques ne sont par
          ailleurs pas agrégées de manière à pouvoir identifier un utilisateur
          particulier du système.
        </p>
        <p>
          Vous pouvez visiter le site Web sans nous dire qui vous êtes ni
          révéler d'informations permettant à quelqu'un de vous identifier en
          tant qu'individu spécifique et identifiable. Toutefois, si vous
          souhaitez utiliser certaines fonctionnalités du site web, ou si vous
          souhaitez recevoir notre lettre d'information ou fournir d'autres
          détails en remplissant un formulaire, vous pouvez nous fournir des
          données personnelles, telles que votre adresse électronique, votre
          prénom, votre nom, votre ville de résidence, votre organisation, votre
          numéro de téléphone. Vous pouvez choisir de ne pas nous fournir vos
          données personnelles, mais il se peut alors que vous ne puissiez pas
          profiter de certaines fonctionnalités du site web. Par exemple, vous
          ne pourrez pas recevoir notre bulletin d'information ou nous contacter
          directement à partir du site web. Les utilisateurs qui ne savent pas
          quelles informations sont obligatoires sont invités à nous contacter
          via {CONTACT_EMAIL}.
        </p>
        <h2>Vos droits :</h2>
        <p>
          Si vous êtes un résident européen, vous disposez des droits suivants
          relatifs à vos données personnelles :
          <ul>
            <li>Le droit d'être informé.</li>
            <li>Le droit d'accès.</li>
            <li>Le droit de rectification.</li>
            <li>Le droit à l'effacement.</li>
            <li>Le droit de restreindre le traitement.</li>
            <li>Le droit à la portabilité des données.</li>
            <li>le droit d'opposition.</li>
            <li>
              Droits relatifs à la prise de décision automatisée et au
              profilage.
            </li>
          </ul>
          Si vous souhaitez exercer ce droit, veuillez nous contacter via les
          coordonnées ci-dessous.
        </p>
        <p>
          En outre, si vous êtes un résident européen, nous notons que nous
          traitons vos informations afin d'exécuter les contrats que nous
          pourrions avoir avec vous (par exemple, si vous passez une commande
          par le biais du Site), ou autrement pour poursuivre nos intérêts
          commerciaux légitimes énumérés ci-dessus. En outre, veuillez noter que
          vos informations peuvent être transférées en dehors de l'Europe, y
          compris au Canada et aux États-Unis.
        </p>
        <h2>Liens vers d'autres sites web :</h2>
        <p>
          Notre site web peut contenir des liens vers d'autres sites web qui ne
          sont pas détenus ou contrôlés par nous. Sachez que nous ne sommes pas
          responsables de ces autres sites web ni des pratiques de
          confidentialité des tiers. Nous vous encourageons à être attentif
          lorsque vous quittez notre site Web et à lire les déclarations de
          confidentialité de chaque site Web susceptible de recueillir des
          informations personnelles.
        </p>
        <h2>Sécurité des informations :</h2>
        <p>
          Nous sécurisons les informations que vous fournissez sur des serveurs
          informatiques dans un environnement contrôlé et sécurisé, protégé
          contre tout accès, utilisation ou divulgation non autorisés. Nous
          maintenons des mesures de protection administratives, techniques et
          physiques raisonnables pour nous protéger contre tout accès,
          utilisation, modification et divulgation non autorisés des données
          personnelles sous son contrôle et sa garde. Toutefois, aucune
          transmission de données sur Internet ou sur un réseau sans fil ne peut
          être garantie.
        </p>
        <h2>Divulgation légale :</h2>
        <p>
          Nous divulguerons toute information que nous collectons, utilisons ou
          recevons si la loi l'exige ou le permet, par exemple pour nous
          conformer à une citation à comparaître ou à une procédure judiciaire
          similaire, et lorsque nous pensons de bonne foi que la divulgation est
          nécessaire pour protéger nos droits, votre sécurité ou celle d'autrui,
          enquêter sur une fraude ou répondre à une demande du gouvernement.
        </p>
        <h2>Informations de contact :</h2>
        <p>
          Si vous souhaitez nous contacter pour mieux comprendre la présente
          Politique ou si vous souhaitez nous contacter concernant toute
          question relative aux droits individuels et à vos Informations
          personnelles, vous pouvez envoyer un courriel à {CONTACT_EMAIL}.
        </p>
      </main>
      <Footer />
    </>
  );
};

export default Legal;
