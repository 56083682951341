import React from "react";

export const enum InputSpan {
  S,
  M,
  L,
}

export type TextInputProps<T = string> = {
  placeholder: T;
  onChange: (newValue: T) => void;
  value?: T;
  span?: InputSpan;
  className?: string;
  name?: string
  type?: string
};

export type DateInputProps<T = string | Date | number> = {
  placeholder: React.ReactNode | React.ReactNode[];
  onChange: (newValue: T) => void;
  value?: T;
  span?: InputSpan;
  className?: string;
  name?: string
};

export type TwoOrMoreArray<T> = {
  0: T;
  1: T;
} & T[];

export type SelectInputProps = TextInputProps<React.ReactNode> & {
  options: TwoOrMoreArray<React.ReactNode>;
  mode?: '-dark'
};

export type ButtonInputProps = {
  className?: string;
  placeholder: string;
  onClick: () => void;
  Head?: React.ReactNode;
  Tail?: React.ReactNode;
  span?: InputSpan;
};

export type SendInputProps = {
  className?: string;
  content: React.ReactNode | React.ReactNode[];
  onClick: () => void;
  span?: InputSpan;
  disabled?: boolean;
  loading?: boolean;
};
