import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import useDiffState from "@oriun/use-diff-state";
import "./Contact.scss";
import {
  TwoOrMoreArray,
  TextInput,
  InputSpan,
  SelectInput,
  ButtonInput,
  NoInput,
  SendInput,
  // DateInput
} from "../../components/Inputs";
import DispoPopup, { Day } from "../../components/DispoPopup";
import UploadPopup from "../../components/UploadPopup";
import { useNavigate, useLocation } from "react-router-dom";
import { DateInput } from "../../components/Inputs/Inputs";
import { API_URL } from "../../constantes";
import useFeedback from "@oriun/use-feedback";
import { Helmet } from "react-helmet";

const prestations: TwoOrMoreArray<string> = [
  "Chirurgie faciale",
  "Greffe de cheveux",
  "Chirurgie dentaire",
  "Chirurgie de la silhouette",
  "Chirurgie mammaire",
];

const _18ans = Date.now() - 18 * 364.25 * 24 * 60 * 60 * 1_000;
const Contact: React.FC = () => {
  const [dispoOpen, setDispo] = React.useState<boolean>(false);
  const [uploadOpen, setUpload] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const location = useLocation();
  const pre = new URLSearchParams(location.search).get("pre");
  const presta = pre
    ? prestations.includes(pre)
      ? pre
      : prestations[0]
    : undefined;
  const [formState, updateForm, hardUpdate] = useDiffState<any>(
    presta ? { presta } : {}
  );
  const sendfeedback = useFeedback();
  const navigate = useNavigate();
  function sendForm() {
    if(loading) return
    const body = new FormData();
    body.append("lastName", formState.lastName);
    body.append("firstName", formState.firstName);
    body.append("civility", formState.civility);
    body.append("birthDate", formState.birthDate);
    body.append("email", formState.email);
    body.append("zipCode", formState.zipCode);
    body.append("availability", JSON.stringify(formState.availability));
    body.append("phoneNumber", formState.phoneNumber);
    body.append("message", formState.message || "");
    body.append("operation", formState.operation);
    body.append("CGUAgreed", formState.cgu);
    (formState.files as File[])?.forEach((file) => body.append("image", file));
    setLoading(true);
    fetch(API_URL + "/patient", {
      method: "POST",
      body,
    })
      .then((res) => {
        if (res.ok) navigate("/form-complete");
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }
  const canSend: true | (() => void) = React.useMemo(() => {
    const a = formState;
    let error: { title: string; message?: string } | null = null;
    if (!a.lastName) error = { title: "Vous devez renseigner votre nom" };
    else if (!a.firstName)
      error = { title: "Vous devez renseigner votre prénom" };
    else if (!a.civility)
      error = { title: "Vous devez renseigner votre état civil" };
    else if (!a.birthDate)
      error = { title: "Vous devez renseigner votre date de naissance" };
    else if (!(new Date(a.birthDate).getTime() < _18ans))
      error = {
        title: "Date de naissance invalide",
        message: "Vous devez être majeur(e)",
      };
    else if (!a.email)
      error = { title: "Vous devez renseigner votre adresse email" };
    else if (!a.email.includes("@"))
      error = { title: "Adresse email invalide" };
    else if (!a.zipCode)
      error = { title: "Vous devez renseigner votre code postal" };
    else if (!a.availability?.length)
      error = {
        title: "Vous devez renseigner vos disponibilités",
        message: "Vous serez rappelé(e) par un de nos conseillers",
      };
    else if (!a.phoneNumber)
      error = {
        title: "Numéro de telephone manquant",
        message: "Vous serez rappelé(e) par un de nos conseillers",
      };
    else if (a.phoneNumber.length < 10 || /[^+\d]/gim.test(a.phoneNumber))
      error = { title: "Téléphone invalide" };
    // else if (!a.message) return false;
    else if (!a.operation)
      error = { title: "Vous devez choisir un type de prestation" };
    else if (!a.cgu)
      error = { title: "Vous devez accepter les conditions d'utilisation" };

    if (error !== null)
      return () =>
        sendfeedback({
          title: error!.title,
          success: false,
          message: error!.message,
        });
    return true;
  }, [formState, sendfeedback]);
  return (
    <>
      <Header />
      <Helmet>
        <title>Demandez un devis et une téléconsultation gratuitement</title>
        <meta
          name="description"
          content="Remplissez notre formulaire et nous vous contacterons par téléphone selon vos disponibilités"
        />
      </Helmet>
      <main className="contact">
        <div className="centered">
          <div className="head">
            <h1 className="title">Azurea Esthetic Travel</h1>
            <p className="pgh-subtitle">
              Demandez un devis et une téléconsultation gratuitement en
              remplissant ce formulaire
            </p>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="mid" />
            <h6 className="sur-pgh">Vos informations</h6>
            <h6 className="sur-pgh">La prestation désirée</h6>
            <SelectInput
              value={formState.civility}
              onChange={(a) => updateForm({ civility: a })}
              placeholder="Civilité"
              options={["Mr", "Mme"]}
            />
            <SelectInput
              value={formState.operation}
              onChange={(a) => updateForm({ operation: a })}
              placeholder="Type de prestation"
              options={prestations}
            />
            <TextInput
              value={formState.lastName}
              onChange={(a) => updateForm({ lastName: a })}
              placeholder="Nom"
              span={InputSpan.S}
            />
            <TextInput
              value={formState.firstName}
              onChange={(a) => updateForm({ firstName: a })}
              placeholder="Prénom"
              span={InputSpan.S}
            />
            <TextInput
              value={formState.message}
              onChange={(a) => updateForm({ message: a })}
              placeholder="Message (maximum 150 caractères)"
              span={InputSpan.L}
            />
            <DateInput
              value={formState.birthDate}
              onChange={(a) => updateForm({ birthDate: a })}
              placeholder="Date de naissance"
              span={InputSpan.S}
            />
            <TextInput
              value={formState.zipCode}
              onChange={(a) => updateForm({ zipCode: a })}
              placeholder="Code postal"
              span={InputSpan.S}
            />
            <TextInput
              value={formState.email}
              onChange={(a) => updateForm({ email: a })}
              placeholder="Address mail"
            />
            <ButtonInput
              Head={<img src="/img/upload.svg" alt="" />}
              placeholder="Ajouter des photos"
              onClick={() => setUpload(true)}
            />
            <TextInput
              value={formState.phoneNumber}
              onChange={(a) => updateForm({ phoneNumber: a })}
              placeholder="Téléphone"
            />
            <ButtonInput
              Head={<img src="/img/calendar.svg" alt="" />}
              Tail={
                <img
                  src="/img/select-arrow.svg"
                  alt=""
                  style={{ transform: "rotate(270deg)" }}
                />
              }
              placeholder="Je peux être rappelé le..."
              onClick={() => setDispo(true)}
            />
            <NoInput
              content={
                <div
                  className="sp"
                  style={{ color: "var(--dark-blue)", cursor: "pointer" }}
                  onClick={() =>
                    updateForm((a: any) => ({ ...a, cgu: !a.cgu }))
                  }
                >
                  <input type="checkbox" checked={formState.cgu} /> En cochant
                  cette case, vous reconnaissez avoir accepté nos conditions
                  générales d'utilisation et d'être rappelé par nos services.
                </div>
              }
            />
            <SendInput
              content="Envoyer"
              onClick={canSend === true ? sendForm : canSend}
              disabled={canSend !== true}
              loading={loading}
            />
          </form>
        </div>
      </main>
      <Footer />
      {dispoOpen && (
        <DispoPopup
          onChange={(a) => hardUpdate((f: any) => ({ ...f, availability: a }))}
          values={formState.availability ?? []}
          days={[
            Day.Lundi,
            Day.Mardi,
            Day.Mercredi,
            Day.Jeudi,
            Day.Vendredi,
            Day.Samedi,
            Day.Dimanche,
          ]}
          spans={["9h-13h", "14h-18h", "+18h"]}
          close={() => setDispo(false)}
        />
      )}
      {uploadOpen && (
        <UploadPopup
          onChange={(a: File[]) => hardUpdate((f: any) => ({ ...f, files: a }))}
          values={formState.files ?? []}
          close={() => setUpload(false)}
        />
      )}
    </>
  );
};

export default Contact;
