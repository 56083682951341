import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./Legal.scss";

// const SITE_URL = "https://azurea-esthetic-travel.fr";
// const PROPRIETAIRE = "AZUREA CONSULTING";
// const PROPRIETAIRE_EMAIL = "contact@azurea-esthetic-travel.fr";
// const WEBMASTER = "Nadhir Boussaadia";
// const WEBMASTER_EMAIL = "contact@azurea-esthetic-travel.fr";

const Legal = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <Helmet>
        <title>Mentions légales</title>
        <link rel="canonical" href="/mentions-legales" />
      </Helmet>
      <main className="legal">
        <button className="back" onClick={() => navigate(-1)}>
          <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM21 7L1 7V9L21 9V7Z"
              fill="black"
            />
          </svg>
          Retour
        </button>
        <h2>Mentions Légales</h2>
        <p>Date de dernière mise à jour : 16/05/2022</p>
        <div>
          Ce site est sous la responsabilité de AZUREA CONSULTING. <br></br>Siret: 908
          819 667 00019
        </div>
        <h3>Coordonnées de la société :</h3>
        <ul>
          <li>
            par courrier : 41 avenue Louis Cappatti, 06200 Nice, FRANCE
          </li>
          <li>par email : contact@azurea-esthetic-travel.fr</li>
        </ul>
      </main>
      <Footer />
    </>
  );
};

export default Legal;
