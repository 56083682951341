import React from 'react'
import './StepBlock.scss'

type StepBlockProps = {
  index?: number;
  title: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  lineWidth?: number|string;
}
const StepBlock: React.FC<StepBlockProps> = ({ index = 1, title, children, lineWidth = 80 }) =>{
  return <div className="step_block">
    <h6>{index}</h6>
    <h4 className='subtitle'>{title}</h4>
    <div className='line' style={{ width: lineWidth }}/>
    {children}
  </div>
}

export default StepBlock;