import React from "react";
import AdminHeader from "../../components/AdminHeader";
import { SendInput, TextInput } from "../../components/Inputs";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import useFeedback from "@oriun/use-feedback";
import { API_URL } from "../../constantes";

const Login = () => {
  const [email, setE] = React.useState("");
  const [password, setP] = React.useState("");
  const [loading, setL] = React.useState(false);
  const navigate = useNavigate();
  const sendfeedback = useFeedback();
  function submit() {
    setL(true);
    fetch(API_URL + "/admin/login", {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const token = (await res.json()).access_token;
          sessionStorage.setItem("token", token);
          navigate("/admin");
        } else {
          const error = await res.json()
          sendfeedback({
            title: "Echec",
            success: false,
            message:
              Array.isArray(error.message) ? error.message[0] : error.message,
          });
          // Else Failure
        }
      })
      .catch((err) => {
        console.error(err);
        sendfeedback({
          title: "Une erreur inattendue est survenue",
          success: false,
          message:
            "Si le problème persiste, veuillez contacter un administrateur.",
        });
      })
      .finally(() => setL(false));
  }
  return (
    <>
      <AdminHeader />
      <main className="login">
        <form onSubmit={(e) => e.preventDefault()}>
          <h1 className="title">Connexion</h1>
          <TextInput
            placeholder="Email"
            value={email}
            onChange={setE}
            className="login-input"
          />
          <TextInput
            placeholder="Mot de passe"
            value={password}
            onChange={setP}
            className="login-input"
            type="password"
          />
          <SendInput
            className="login-send"
            onClick={submit}
            content={loading ? "Connexion en cours..." : "Se connecter"}
          />
        </form>
      </main>
    </>
  );
};

export default Login;
