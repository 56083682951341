import React from "react";
import { useNavigate } from "react-router-dom";
import MediaPopup from "../../../components/MediaPopup";
import QuoteBlock from "../../../components/QuoteBlock";
import { API_URL } from "../../../constantes";

type Feedback = {
  _id: string;
  firstName: string;
  message: string;
  starsNumber: number;
  patientId: string;
  createdDate: number;
};
function getFeedback(signal?: AbortSignal): Promise<Feedback[]> {
  return fetch(API_URL + "/feedback", {
    headers: { "Content-type": "application/json" },
    signal
  }).then((res) => res.json());
}

const Section_07 = () => {
  const navigate = useNavigate();
  const first = React.useRef<HTMLDivElement>(null);
  const last = React.useRef<HTMLDivElement>(null);
  const scrollable = React.useRef<HTMLDivElement>(null);
  const [arrows, setA] = React.useState<[boolean, boolean]>([false, true]);
  const [feedbacks, setFeedback] = React.useState<Feedback[]>([])
  const [zoomed, setZoomed] = React.useState<string | null>(null)
  React.useEffect(()=>{
    const abort = new AbortController()
    getFeedback(abort.signal)
    .then(setFeedback)
    return ()=>abort.abort()
  },[])
  const featuredImages: string[] = [
    "/img/galerie/f-1.jpg",
    "/img/galerie/c-2.jpg",
    "/img/galerie/f-6.jpg",
    "/img/galerie/s-2.jpg",
    "/img/galerie/f-7.jpg",
    "/img/galerie/c-3.jpg",
    "/img/galerie/c-8.jpg",
    "/img/galerie/f-12.jpg",
    "/img/galerie/m-2.jpg",
    "/img/galerie/f-11.jpg",
    "/img/galerie/s-1.jpeg",
    "/img/galerie/m-1.jpeg",
  ];
  function scroll(forward: boolean = true): React.MouseEventHandler {
    return function () {
      if (!scrollable.current) return;
      const cardWidth = 488;
      const cardGap = 24;
      const next =
        (forward ? -1 : 1) * (cardWidth + cardGap) +
        scrollable.current.scrollLeft;
      console.log(
        scrollable.current.scrollLeft,
        next,
        next % (cardWidth + cardGap),
        next - (next % (cardWidth + cardGap))
      );
      scrollable.current.scrollTo({
        left: next - (next % (cardWidth + cardGap)),
        behavior: "smooth",
      });
    };
  }
  React.useEffect(() => {
    console.log(first.current, last.current)
    if (!first.current || !last.current) return;

    const f = first.current;
    const l = last.current;
    function observerCallback(entries: IntersectionObserverEntry[]) {
      console.log('observer')
      const a = entries[0];
      const b = entries[1];
      const left = a.target === f ? a : b;
      const right = b && (b.target === l ? b : a);
      const arr: [boolean, boolean] = [!1, !1];
      if (left && left.intersectionRatio < 1) arr[0] = true;
      if (right && right.intersectionRatio < 1) arr[1] = true;
      if (arr[0] !== arrows[0] || arr[1] !== arrows[1]) setA(arr);
    }
    const observerOptions: IntersectionObserverInit = {
      threshold: [1.0, 0.9, 0.1, 0],
      // root: scrollable.current || ,
    };
    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    observer.observe(f);
    observer.observe(l);
    return () => {
      observer.unobserve(f);
      observer.unobserve(l);
    };
  });
  // }, [arrows]);
  return (
    <section className="sect_07">
      <h2 className="title">Nos patients sont satisfaits !</h2>
      <div className="carousel">
        {arrows[0] && (
          <div className="control l" onClick={scroll(!0)}>
            <img src="/img/arrow.svg" alt="" />
          </div>
        )}
        {arrows[1] && (
          <div className="control r" onClick={scroll(!1)}>
            <img src="/img/arrow.svg" alt="" />
          </div>
        )}
        <div className="scrollable noscrollbar" ref={scrollable} dir="ltr">
          {feedbacks.map(({message, firstName}, i) => {
            const refHere = i === 0 ? first : i === feedbacks.length - 1 ? last : undefined
            console.log(i, refHere)
            return <QuoteBlock className="pgh" key={firstName+i} ref={refHere} author={firstName}>{message}</QuoteBlock>
          })}
        </div>
      </div>
      <div className="grid">
        {featuredImages.map((a) => (
          <img src={a} alt="" key={a} onClick={()=>setZoomed(a)}/>
        ))}
        <div className="overlay">
          <div className="line" />
          <button
            className="dark-button btn"
            onClick={() => navigate("/galerie")}
          >
            Voir plus d'avant-après
          </button>
          <div className="line" />
        </div>
      </div>
      {zoomed !== null && <MediaPopup close={()=>setZoomed(null)}>
          <img src={zoomed} alt="" style={{ maxWidth: '80vw', maxHeight: '80vh', width: '100vw', objectFit: 'contain'}} />
      </MediaPopup>}
    </section>
  );
};

export default Section_07;
