import React from "react";
import Popup from "../../Layouts/Popup";
import "./DispoPopup.scss";

type TextCheckBoxProps = {
  name?: string;
  checked?: boolean;
  onClick: (a: boolean) => void;
  children?: React.ReactNode | React.ReactNode[];
};

const TextCheckBox: React.FC<TextCheckBoxProps> = ({
  name,
  checked = false,
  onClick,
  children,
}) => {
  return (
    <div className={"text-checkbox"+(checked ? ' on':'')} onClick={() => onClick(!checked)}>
      <input type="checkbox" name={name} defaultChecked={checked} />
      {children}
    </div>
  );
};

export const enum Day {
  Lundi = "Lundi",
  Mardi = "Mardi",
  Mercredi = "Mercredi",
  Jeudi = "Jeudi",
  Vendredi = "Vendredi",
  Samedi = "Samedi",
  Dimanche = "Dimanche",
}
export const AllWeek: Day[] = [
  Day.Lundi,
  Day.Mardi,
  Day.Mercredi,
  Day.Jeudi,
  Day.Vendredi,
  Day.Samedi,
  Day.Dimanche,
];
export const WeekEnd: Day[] = [Day.Samedi, Day.Dimanche];
export const OfficeDays: Day[] = [
  Day.Lundi,
  Day.Mardi,
  Day.Mercredi,
  Day.Jeudi,
  Day.Vendredi,
];
export const SchoolDays: Day[] = [
  Day.Lundi,
  Day.Mardi,
  Day.Jeudi,
  Day.Vendredi,
];

type DispoSpan = string;
type DispoItem = {
  day: Day;
  span: DispoSpan;
};
type DispoPopupProps = {
  onChange: (d: DispoItem[]) => void;
  values: DispoItem[];
  days?: Day[];
  spans: DispoSpan[];
  close: () => void;
};
const DispoPopup: React.FC<DispoPopupProps> = ({
  close,
  days = AllWeek,
  spans,
  onChange,
  values,
}) => {
  console.log(values)
  function check(day: Day, span: DispoSpan) {
    return (v: boolean) => {
      onChange(
        v
          ? [...values, { day, span }]
          : [...values].filter((a) => !(a.day === day && a.span === span))
      );
    };
  }
  return (
    <Popup close={close}>
      <div className="dispo-popup">
        <div className="dispo-head">
          <h6 className="sur-pgh">Sélectionnez vos disponibilités</h6>
          <img src="/img/close.svg" alt="" width={20} height={20} onClick={close} />
        </div>
        <div className="dispo-main">
          {days.map((d) => (
            <span key={d} className="sub-pgh day">
              {d}
            </span>
          ))}
          {spans.map((s) => {
            return days.map((d) => {
              const checked = !!values.find((a) => a.day === d && a.span === s);
              return (
                <TextCheckBox onClick={check(d, s)} checked={checked}>
                  <span
                    className={
                      "dispo-span btn small -little-btn" + (checked ? " checked" : "")
                    }
                  >
                  {s}
                  </span>
                </TextCheckBox>
              );
            });
          })}
        </div>
        <button onClick={close} className="btn dark-button small">
          Confirmer
        </button>
      </div>
    </Popup>
  );
};

export default DispoPopup;
