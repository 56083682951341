import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { InputSpan, SendInput, TextInput } from "../../components/Inputs";
import TwoBlocks, { Directions } from "../../Layouts/TwoBlocks";
import "./Avis.scss";
import useDiffState from "@oriun/use-diff-state";
import { API_URL } from "../../constantes";
import StarRating from "../../components/StarRating/StarRating";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import useFeedback from "@oriun/use-feedback";

type AvisForm = {
  message: string;
  firstName: string;
  starsNumber: number;
  patientId: string;
};
const Avis = () => {
  const location = useLocation();
  const [formState, setF] = useDiffState<Partial<AvisForm>>({
    patientId: new URLSearchParams(location.search).get("contact") ?? "",
  });
  const navigate = useNavigate();
  const sendFeedback = useFeedback();
  if (!formState.patientId) {
    return <Navigate to="/" />;
  }
  function send() {
    fetch(API_URL + "/feedback", {
      method: "POST",
      body: JSON.stringify(formState),
      headers:{
        "Content-type": "application/json"
      }
    })
      .then((res) => {
        if (res.ok) {
          navigate("/avis-complete");
        } else {
          res
            .json()
            .then((r) => console.log("Failure", res.status, res.statusText, r));
          sendFeedback({
            title: "Une erreur inattendue est survenue",
            success: false,
            message: "Veuillez réessayer ultérieurement.",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        sendFeedback({
          title: "Une erreur inattendue est survenue",
          success: false,
          message:
            "Si le problème persiste, veuillez contacter un administrateur.",
        });
      });
  }
  console.log(formState);
  return (
    <>
      <Header />
      <main className="feedback">
        <TwoBlocks
          className="blocks"
          direction={Directions.RTL}
          right={
            <div className="content">
              <h2 className="title">
                Comment s'est passée votre intervention ?
              </h2>
              <p className="pgh-subtitle">
                Vous appréciez votre nouveau corps ? La Tunisie vous a fait du
                charme ? Faites nous part de votre satisfaction en laissant un
                avis.
              </p>
            </div>
          }
          left={
            <form
              onSubmit={(e) => e.preventDefault()}
              className="feedback-form"
            >
              <StarRating
                min={0}
                className="stars"
                max={5}
                onChange={(a) => setF({ starsNumber: a })}
                current={formState.starsNumber ?? 0}
              />
              <TextInput
                span={InputSpan.M}
                placeholder={"Prenom"}
                onChange={(a) => setF({ firstName: a })}
                value={formState.firstName}
              />
              <TextInput
                span={InputSpan.L}
                placeholder={"Message"}
                onChange={(a) => setF({ message: a })}
                value={formState.message}
              />
              <SendInput content={"Envoyer"} onClick={send} />
            </form>
          }
        />
        {/* <img src="/img/plane.svg" alt="" height={102} width={102} /> */}
      </main>
      <Footer />
    </>
  );
};

export default Avis;
