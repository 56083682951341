import React from "react";
import Popup from "../../Layouts/Popup";
import "./MediaPopup.scss";

type MediaPopupProps = {
  children: React.ReactNode;
  close: () => void;
  className?: string;
};

const MediaPopup: React.FC<MediaPopupProps> = ({
  children,
  close,
  className = "",
}) => {
  return (
    <Popup className={"media-popup " + className} close={close}>
      <img src="/img/close-light.svg" alt="" className="close" onClick={close} />
      {children}
    </Popup>
  );
};

export default MediaPopup;
