/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import "./HomePage.scss";

import Section_01 from "./sections/Section_01";
import Section_02 from "./sections/Section_02";
import Section_03 from "./sections/Section_03";
// import Section_04 from "./sections/Section_04";
import Section_05 from "./sections/Section_05";
import Section_06 from "./sections/Section_06";
import Section_07 from "./sections/Section_07";

const HomePage: React.FC = () => {
  return (
    <>
      <Header />
      <main className="home">
        <Hero />
        <div style={{ width: "100%", height: 100, backgroundColor: "white" }} />
        <Section_01 />
        <Section_02 />
        <div style={{ width: "100%", height: 120, backgroundColor: "white" }} className="desktop-only" />
        <div
          className="blue_part"
          // style={{ backgroundImage: "url('/img/bg.png')" }}
        >
          <Section_03 />
          {/* <Section_04
          img="/img/sect_02_img.jpg"
        /> */}
          <Section_05 />
          <Section_06 />
        </div>
        <Section_07 />
      </main>
      <Footer />
    </>
  );
};

export default HomePage;
