import React from 'react'
import './TwoBlocks.scss'

export const enum Directions{
    LTR,
    RTL
};

type TwoBlocksProps = {
    left: React.ReactNode,
    right: React.ReactNode,
    className?: string
    direction?: 0 | 1
}

const TwoBlocks: React.FC<TwoBlocksProps> = ({ left, right, direction = Directions.LTR, className="" }) => {
  return (
    <section className={"two_blocks "+className+" "+(direction=== Directions.RTL ? "inverse":"")}>
        {direction ===Directions.LTR ? left : right}
        {direction ===Directions.LTR ? right : left}
    </section>
  )
}

export default TwoBlocks