import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";
import data from "./Prestations.json";
import "./Prestations.scss";
import { useMedia } from "@oriun/use-media";
import { Helmet } from "react-helmet";

function chunks<T>(table: T[], size: number): T[][] {
  return Array.from({ length: Math.ceil(table.length / size) }).map((_, i) =>
    table.slice(size * i, size * (i + 1))
  );
}

type DataType = {
  [key: string]: {
    title: string;
    subtitle: string;
    columnCount: number;
    cards: {
      img: string;
      title?: string;
      content?: string;
    }[];
  };
};
function getCardsInRow(queries: {
  [key: string]: boolean;
}): number | undefined {
  const active_queries = Object.keys(queries).filter(
    (a) => queries[a as unknown as keyof typeof queries] === true
  );
  const parsed_queries = active_queries.map((a) => parseInt(a));
  parsed_queries.sort((a, b) => a - b);
  return parsed_queries[0];
}
const Prestations = () => {
  const { pathname: category } = useLocation();
  const route = React.useMemo(
    () => (data as DataType)[category] ?? {},
    [category]
  );
  const [queries, ref] = useMedia({
    "3col": "max-width:1900px",
    "2col": "max-width:1080px",
    "1col": "max-width:780px"
  });
  // const cards_in_row = getCardsInRow(queries) ?? 4;
  const cards_in_row = Math.min(
    route.cards.length,
    getCardsInRow(queries) ?? 4
  );

  const card_chunks = chunks(route.cards, cards_in_row);
  // while (card_chunks.at(-1)!.length < cards_in_row) {
  //   card_chunks.at(-1)?.push({
  //     img: "",
  //     title: "",
  //     content: "",
  //   });
  // }
  return (
    <>
      <Header />
      <Helmet>
        <title>{route.title} - Azurea Esthetic Travel</title>
        <meta name="description" content={route.subtitle} />
      </Helmet>
      <main className="prestation" ref={ref}>
        <h1 className="big-title">Perstation</h1>
        <h2 className="title">{route.title}</h2>
        <p className="pgh">{route.subtitle}</p>
        <div
          className="grid"
          style={{
            gridTemplateColumns: `repeat(${cards_in_row}, 1fr)`,
            gridTemplateRows: `repeat(${Math.ceil(
              route.cards.length / cards_in_row
            )}, 1fr auto)`
          }}
        >
          {card_chunks.map((cards) => {
            return (
              <>
                {cards.map(({ img }) =>
                  img ? (
                    <img src={img} alt="" style={{ maxHeight: 350 }} />
                  ) : (
                    <div />
                  )
                )}
                {cards.map(({ title, content }) => (
                  <div>
                    <h4 className="subtitle">{title}</h4>
                    <p className="pgh">{content}</p>
                  </div>
                ))}
              </>
            );
          })}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Prestations;
