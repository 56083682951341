import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import HomePage from "./views/HomePage";
import { DesignGuidelines } from "@oriun/gray-cat";
import Contact from "./views/Contact";
import FormComplete from "./views/FormComplete";
import Galerie from "./views/Galerie";
import Legal from "./views/Legal";
import Prestations from "./views/Prestations";
import Privacy from "./views/Privacy";
import About from "./views/About/About";
import Login from "./views/Login";
import Admin from "./views/Admin";
import Avis from "./views/Avis";
import { Provider as Feedback } from "@oriun/use-feedback";
import CGU from "./views/CGU";


const ScrollTopOnLocationChange: React.FC = () => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0,0);
  }, [location.pathname]);
  return null;
};

function App() {
  return (
    <Feedback>
      <BrowserRouter>
        <DesignGuidelines />
        <ScrollTopOnLocationChange />
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/form-complete"
            element={
              <FormComplete
                title="Demande envoyée"
                content="Vous allez recevoir un email de confirmation de votre demande. Nous prendrons contact avec vous bientot selon vos disponibilités."
              />
            }
          />
          <Route
            path="/avis-complete"
            element={
              <FormComplete
                title="Merci pour votre avis !"
                content="Nous avons bien reçu votre commentaire. Nous l'utiliserons pour améliorer continuellement notre service."
              />
            }
          />
          <Route path="/galerie" element={<Galerie />} />
          <Route path="/about" element={<About />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/avis" element={<Avis />} />
          {["/mentions-legales", "/legal", "/mention-legales", "/mentions"].map(
            (legal) => (
              <Route path={legal} element={<Legal />} key={legal} />
            )
          )}
          <Route path="/cgu" element={<CGU />} />
          {[
            "/privacy",
            "/policies",
            "/policy",
            "/privacy-policy",
            "/privacy-policies",
            "/confidentialite",
            "/politique-confidentialite",
            "/politique-de-confidentialite",
            "/vie-privee",
          ].map((legal) => (
            <Route path={legal} element={<Privacy />} key={legal}/>
          ))}
          {[
            "/chirurgie-faciale",
            "/greffe-cheveux",
            "/chirurgie-mammaire",
            "/chirurgie-dentaire",
            "/chirurgie-silhouette",
          ].map((presta) => (
            <Route path={presta} element={<Prestations />} key={presta}/>
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </Feedback>
  );
}

export default App;
