import React from "react";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../../components/BasicCard";

const Section_03 = () => {
  const navigate = useNavigate();
  return (
    <section className="sect_03">
      <h2 className="title">Notre expertise</h2>
      <div className="grid">
        <BasicCard
          title="Chirurgie dentaire"
          img="/img/dent.jpg"
          onClick={() => navigate("/chirurgie-dentaire")}
        />
        <BasicCard
          title="Chirurgie faciale"
          img="/img/visage.jpg"
          onClick={() => navigate("/chirurgie-faciale")}
        />
        <BasicCard
          title="Greffe de cheveux"
          img="/img/cheveux.jpg"
          onClick={() => navigate("/greffe-cheveux")}
        />
        <BasicCard
          title="Chirurgie mammaire"
          img="/img/seins.jpg"
          onClick={() => navigate("/chirurgie-mammaire")}
        />
        <BasicCard
          title="Rhinoplastie"
          img="/img/nez2.jpg"
          onClick={() => navigate("/chirurgie-faciale")}
        />
        <BasicCard
          title="Chirurgie de la silhouette"
          img="/img/silhouette.jpg"
          onClick={() => navigate("/chirurgie-silhouette")}
        />
      </div>
    </section>
  );
};

export default Section_03;
