import React from "react";
import "./Popup.scss";

type PopupProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  close: () => void;
};

const Popup: React.ForwardRefExoticComponent<
  PopupProps & React.RefAttributes<HTMLDivElement>
> = React.forwardRef(({ children, className = "", close }, ref) => {
  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);
  return (
    <div className={"popup-overlay " + className} ref={ref} onClick={close}>
      <div className="content" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
});

export default Popup;
